import styled, { css } from 'styled-components';
import { color } from '../../../atoms/colors';
import { font } from '../../../atoms/typography';

export const TrustbarStandardWrapper = styled.div`
  background-color: ${props => props.background === 'light' ? `${color.grey[100]}` : props.background === 'white' && `${color.common.white}`};
  background-size: cover;
  background-position: center;
  padding: 96px 0;
  font-family: 'Roboto';
  position: relative;
  z-index: 50;
  @media (max-width: 576px) {
    padding: 64px 0;
  }
  .background {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
  ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
    @media (max-width: 576px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
`

export const Section = styled.div`
  position: relative;
  z-index: 10;
`

export const ContentWrap = styled.div`
  margin: 48px 0 0;
  @media (max-width: 576px) {
    margin: 36px 0 0;
  }
  .marquee {
    justify-content: space-evenly;
  }
`

export const Logo = styled.div`
  height: 45px;
  padding: 0 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 576px) {
    height: 40px;
  }
  .gatsby-image-wrapper{
    height: 100%;
    overflow: visible;
    width: auto;
    div {
      max-height: 45px;
      width: 100%;
      max-width: unset!important;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      height: 45px;
    }
  }
    
`

export const Heading = styled.h2`
  ${font('display', 'xxs', '700')}
  text-align: center;
  color: ${props => props.background === 'dark' ? `${color.common.white}` : props.background === 'light' ? `${color.primary[900]}` : `${color.grey[900]}`};
`
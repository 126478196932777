import React from 'react';
import { TrustbarStandard } from './TrustbarStandard'

const Trustbar = ({ component }) => {
  const {
    type
  } = component
  switch (type) {
    case "standard":
      return <TrustbarStandard component={component} />;
    default: 
      return <TrustbarStandard component={component} />;
  }
}

export default Trustbar
